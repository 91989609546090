import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { BigNumber, Contract } from "ethers";
import ReactPlayer from 'react-player';
import Connect from "../../../buttons/connect";
import Toast from "../../../toast";

import conceptSmall1 from "../../../../images/conceptSmall1.jpg";
import conceptSmall2 from "../../../../images/conceptSmall2.jpg";
import conceptSmall3 from "../../../../images/conceptSmall3.jpg";
import conceptSmall4 from "../../../../images/conceptSmall4.jpg";
import conceptSmall5 from "../../../../images/conceptSmall5.jpg";
import SpinningHab1 from "../../../../videos/SpinningHabitat1.mp4";
import SpinningHab2 from "../../../../videos/SpinningHabitat2.mp4";
import SpinningHab3 from "../../../../videos/SpinningHabitat4.mp4";
import habStill1 from "../../../../images/habStill1.png";
import habStill5 from "../../../../images/habStill5.png";
import habStill8 from "../../../../images/habStill8.png";
import habStill9 from "../../../../images/habStill9.png";
import habStill10 from "../../../../images/habStill10.png";
import habStill12 from "../../../../images/habStill12.png";
import PartnerLogos from "../../../../images/PartnerLogos.png";

const styles = require("./landing-page.module.scss");
const allowTreeJson = require("../json/allow-tree.json");

type HomeProps = {
  accountAddress: string | null,
  getAndSetAccount: Function,
  contract: Contract,
  isMainnet: boolean,
  totalSupply: number | null,
  curSaleState: number | null,
  setConnectButtonElement: (el: HTMLButtonElement) => void,
}

const COLLECTION_SIZE: number = 2500;

/**
 * Home page for SHoP.
 */
const Home: React.FC<HomeProps> = ({ accountAddress, getAndSetAccount, contract, isMainnet, totalSupply, curSaleState, setConnectButtonElement }) => {
  // General state
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [shouldShowSuccessMintToast, setShouldShowSuccessMintToast] = useState<boolean>(false);

  // Minting state
  const [isMinting, setIsMinting] = useState(false);
  const [numTokensToBuy, setNumTokensToBuy] = useState<number>(1);
  const [transactionUrl, setTransactionUrl] = useState<string | null>(null);

  // Contract state
  const [mintPrice, setMintPrice] = useState<number>(0.1);
  const [saleState, setSaleState] = useState<number>(curSaleState ? curSaleState : 3); // 0 = closed, 1 = presale, 2 = allow, 3 = public // TODO: Update
  const [numMinted, setNumMinted] = useState<number>(totalSupply ? totalSupply : 0);
  const [amountMintedByUser, setAmountMintedByUser] = useState<number>(0);

  // Function to do the minting
  const mintHabitats = () => {
    if (isInAllowState) {
      const proof = accountAddress ? allowTreeJson[accountAddress] : null;
      if (proof) {
        return contract.mintPrice().then(async (currentPrice: BigNumber) => {
          const tx = await contract.allowListMint(numTokensToBuy, proof, {
            from: accountAddress,
            value: String(Number(currentPrice.toBigInt()) * numTokensToBuy),
          })
          const { hash } = tx;
          setTransactionUrl("https://etherscan.io/tx/" + hash);
          try {
            const receipt = await tx.wait();
            if (receipt) {
              setIsMinting(false);
              setTransactionUrl(null);
              setShouldShowSuccessMintToast(true);
            }
          } catch (e) {
            setShouldShowError(true);
            setErrorMessage('Transaction failed');
          }
        })
      } else {
        setShouldShowError(true);
        setErrorMessage('You are not on the allowlist. You will be able to mint when the public sale opens.');
      }
    } else {
      return contract.mintPrice().then(async (currentPrice: BigNumber) => {
        const tx = await contract.publicMint(numTokensToBuy, {
          from: accountAddress,
          value: String(Number(currentPrice.toBigInt()) * numTokensToBuy),
        })
        const { hash } = tx;
        setTransactionUrl("https://etherscan.io/tx/" + hash);
        try {
          const receipt = await tx.wait();
          if (receipt) {
            setIsMinting(false);
            setTransactionUrl(null);
            setShouldShowSuccessMintToast(true);
          }
        } catch (e) {
          setShouldShowError(true);
          setErrorMessage('Transaction failed');
        }
      })
    }
  }

  useEffect(() => {
    if (totalSupply) {
      setNumMinted(totalSupply);
    }

    if (curSaleState) {
      setSaleState(curSaleState);
    }
  }, [totalSupply, curSaleState]);

  // Sets contract state
  useEffect(() => {
    // Buy Habitats
    if (isMinting) {
      mintHabitats().then(() => {
        setIsMinting(false);
      }).catch((e: any) => {
        console.error(e);
        setIsMinting(false);
        setShouldShowError(true);
        if (e.code === 4001 && e.message === 'MetaMask Tx Signature: User denied transaction signature.') {
          return;
        } else if (e.message.startsWith('insufficient funds for intrinsic transaction cost')) {
          setErrorMessage(`Connected wallet does not have sufficient funds to mint ${numTokensToBuy} ${numTokensToBuy > 1 ? 'Habitats' : 'Habitat'}`);
        } else {
          setErrorMessage('An error occurred while minting. Please try again and contact the team in Discord if the issue persists.');
        }
      });
    }

    if (contract) {
      contract.totalSupply().then((supply: BigNumber) => {
        setNumMinted(supply.toNumber());
      });

      contract.mintPrice().then((price: BigNumber) => {
        setMintPrice(Number(price.toBigInt()) / 1000000000000000000);
      });

      contract.state().then((saleState: string) => {
        setSaleState(parseInt(saleState));
        // setSaleState(3);
      });

      if (accountAddress) {
        contract.amountMinted(accountAddress).then((amountMinted: BigNumber) => {
          setAmountMintedByUser(amountMinted.toNumber());
          // setAmountMintedByUser(1);
        });
      }
    }
  }, [contract, isMinting, accountAddress]);

  const hasSaleStarted = saleState >= 1;
  const isInPresaleState = saleState === 1;
  const isInAllowState = saleState === 2;
  const isInPublicState = saleState === 3;
  const ineligibleForAllow = amountMintedByUser >= 2 || (accountAddress && !allowTreeJson[accountAddress]);
  const isAtMintLimit =
    isInAllowState
      ? amountMintedByUser + numTokensToBuy >= 2
      : numTokensToBuy === 10
  const isSoldOut = numMinted === COLLECTION_SIZE;

  return (
    <>
      {/* Toasts */}
      {((shouldShowError && errorMessage) &&
        <Toast
        text={errorMessage}
        variant='error'
        closeTime={10000}
        onClose={() => {
          setShouldShowError(false);
          setErrorMessage(null);
        }}
      />
      )}
      {(isMinting && transactionUrl) && (
        <Toast
          text={<span>Mint transaction is pending. You can view the status of the transaction <a  href={transactionUrl || 'https://www.google.com'} target='_blank'>here</a>.</span>}
          variant='success'
          closeTime={20000}
        />
      )}
      {shouldShowSuccessMintToast && (
        <Toast
          text={<span>Your mint transaction completed successfully!</span>}
          variant='success'
          closeTime={10000}
          onClose={() => setShouldShowSuccessMintToast(false)}
        />
      )}

      {/* Mint */}
      <Container className={`${styles.contentContainer} ${styles.mintContainer}`} fluid>
        <Row className={styles.mintRowContainer}>
          <Col className={`${styles.mintSectionContainer} col-12 col-lg-6`}>
            <Row className={styles.mintInfoContainer}>
              <Col>
                <h2
                  className={styles.mintTitle}
                  style={isInAllowState && amountMintedByUser < 2 ? { marginBottom: '0px' } : {}}
                >
                  {isInPresaleState ? 'Allowlist Mint Coming Soon' : 'Mint Habitats'}
                </h2>
                {(isInAllowState && amountMintedByUser < 2 && !ineligibleForAllow) && (
                  <>
                    <h4 className={`${styles.allowStateText} ${styles.highlighted}`}>Allowlist Only</h4>
                    {accountAddress && (
                      <p className={styles.allowListMintsText}>{2 - amountMintedByUser} {amountMintedByUser == 0 ? 'mints' : 'mint'} remaining</p>
                    )}
                  </>
                )}
                {isSoldOut && (
                  <h4 className={`${styles.allowStateText} ${styles.highlighted}`}>Sold Out</h4>
                )}
              </Col>
              <Col>
                {(!isInPresaleState) && (
                  <p className={styles.mintStats}><span className={styles.highlighted}>{numMinted}</span> / 2500</p>
                )}
              </Col>
              {isInPresaleState && (
                <Col className={styles.allowOnlyContainer}>
                  {accountAddress
                    ? <p className={styles.presaleStateText}>{allowTreeJson[accountAddress] ? 'you\'re eligible for 2 allowlist mints!' : 'you\'re not eligible for the allowlist'}</p>
                    :
                    <div className={'d-flex flex-column'}>
                      <p className={styles.presaleStateText}>connect your wallet to check whether you're on the allowlist</p>
                    </div>
                  }
                  {accountAddress && !allowTreeJson[accountAddress]
                    ?
                    <div className='d-flex flex-column'>
                      <span className={styles.presaleStateText}>public mint opens at</span>
                      <span className={styles.presaleStateText}>12:00 PM EDT on July 1st</span>
                    </div>
                    :
                    <div className='d-flex flex-column'>
                      <span className={styles.presaleStateText}>allowlist mint opens at</span>
                      <span className={styles.presaleStateText}>12:00 PM EDT on June 30th</span>
                    </div>
                  }
                  {isInPresaleState && !accountAddress && (
                    <Connect
                      accountAddress={accountAddress}
                      getAndSetAccount={() => getAndSetAccount()}
                      connectComponent={
                        <Button
                          variant="outline-primary"
                          className={styles.presaleConnectButton}
                          style={{width: "100%"}}
                        >
                          CONNECT WALLET
                        </Button>
                      }
                    />
                  )}
                </Col>
              )}
              {(isInAllowState && ineligibleForAllow) && (
                <Col className={styles.allowOnlyContainer}>
                  <h4 className={styles.allowOnlyTitle}>{amountMintedByUser >= 2 ? 'Allowlist Allocation Minted' : 'Allowlist Only'}</h4>
                  {amountMintedByUser >= 2 && (
                    <p className={styles.allowOnlyText}>you minted your full allowlist allocation</p>
                  )}
                  <span className={styles.allowOnlyText}>public mint opens at</span>
                  <span className={styles.allowOnlyText}>12:00 PM EDT on July 1st</span>
                </Col>
              )}
              {((accountAddress && isInAllowState && !ineligibleForAllow) || isInPublicState) && (
                <>
                  {!isSoldOut && (
                    <>
                      <Col className='d-flex justify-content-center align-items-center'>
                        {Array.from(Array(numTokensToBuy).keys()).map((index) => {
                          return <div key={`hab-square-${index}`} className={styles.habSquare} />
                        })}
                      </Col>
                      <Col className={styles.mintNumberContainer}>
                        <p className={styles.mintNumberTitle}>amount</p>
                        <div className={styles.mintNumberSelect}>
                          <span
                            className={!accountAddress || !hasSaleStarted || numTokensToBuy === 1
                              ? styles.minusSelectDisabled
                              : styles.minusSelect
                            }
                            onClick={() => {
                              if (!accountAddress || !hasSaleStarted) {
                                return;
                              }

                              if (numTokensToBuy > 1) {
                                setNumTokensToBuy(numTokensToBuy - 1);
                              }
                            }}
                          >-</span>
                          <span className={styles.mintNumber}>{numTokensToBuy}</span>
                          <span
                            className={!accountAddress || !hasSaleStarted || isAtMintLimit
                              ? styles.plusSelectDisabled
                              : styles.plusSelect
                            }
                            onClick={() => {
                              if (!accountAddress || !hasSaleStarted) {
                                return;
                              }

                              if (isInAllowState) {
                                if (amountMintedByUser + numTokensToBuy < 2) {
                                  setNumTokensToBuy(numTokensToBuy + 1);
                                }
                              } else {
                                if (numTokensToBuy < 10) {
                                  setNumTokensToBuy(numTokensToBuy + 1);
                                }
                              }
                            }}
                            >+</span>
                        </div>
                        <Button
                          variant="primary"
                          disabled={!accountAddress || !hasSaleStarted}
                          className={styles.maxButton}
                          onClick={() => {
                            if (isInAllowState) {
                              setNumTokensToBuy(2 - amountMintedByUser);
                            } else {
                              setNumTokensToBuy(10);
                            }
                          }}
                        >
                          max
                        </Button>
                      </Col>
                      <Col className={styles.mintTotalColumn}>
                        <div className={styles.mintTotalContainer}>
                          <p className={styles.mintTotalTitle}>total</p>
                          <p className={styles.mintTotalAmount}>{(numTokensToBuy * mintPrice).toFixed(1)} ETH</p>
                        </div>
                      </Col>
                    </>
                  )}
                  <Col className='d-flex justify-content-center align-items-center'>
                    {isSoldOut
                      ?
                      <a
                        className={styles.soldOutButton}
                        href={'https://opensea.io/collection/habitats-genesis'}
                        target='_blank'
                      >
                        <Button
                          variant="outline-primary"
                          className={styles.connectButton}
                          style={{width: "100%"}}
                        >
                          VIEW ON OPENSEA
                        </Button>
                      </a>
                      : accountAddress
                        ?
                        <Button
                          variant="primary"
                          disabled={!accountAddress || !hasSaleStarted || !isMainnet}
                          className={styles.mintButton}
                          onClick={() => {
                            setIsMinting(true);
                            setShouldShowSuccessMintToast(false);
                            setShouldShowError(false);
                          }}
                        >
                          {isMinting
                            ?
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            : 'MINT'
                          }
                        </Button>
                        :
                        <Connect
                          accountAddress={accountAddress}
                          getAndSetAccount={() => getAndSetAccount()}
                          connectComponent={
                            <Button
                              variant="outline-primary"
                              className={styles.connectButton}
                              style={{width: "100%"}}
                              ref={(element: HTMLButtonElement) => setConnectButtonElement(element)}
                            >
                              CONNECT WALLET
                            </Button>
                          }
                        />
                    }
                  </Col>
                </>
              )}
              {(isInAllowState && !accountAddress) && (
                <Col className='d-flex justify-content-center align-items-center'>
                    {isSoldOut
                      ?
                      <a className={styles.soldOutButton} href={'https://opensea.io/collection/habitats-land'} target='_blank'>
                        <Button
                          variant="outline-primary"
                          className={styles.connectButton}
                          style={{width: "100%"}}
                        >
                          VIEW ON OPENSEA
                        </Button>
                      </a>
                      :
                      <Connect
                        accountAddress={accountAddress}
                        getAndSetAccount={() => getAndSetAccount()}
                        connectComponent={
                          <Button
                            variant="outline-primary"
                            className={styles.connectButton}
                            style={{ width: "100%", marginTop: "20px" }}
                            ref={(element: HTMLButtonElement) => setConnectButtonElement(element)}
                          >
                            CONNECT WALLET
                          </Button>
                        }
                      />
                    }
                  </Col>
              )}
            </Row>
          </Col>
          <Col className={`${styles.mintSectionContainer} ${styles.mintVideoContainer} col-12 col-lg-6`}>
            <ReactPlayer
              url={SpinningHab2}
              playing={true}
              loop={true}
              controls={false}
              muted={true}
              playsinline={true}
              width='90%'
              height='auto'
            />
          </Col>
        </Row>
      </Container>

      {/* Site */}
      <Container className={hasSaleStarted ? styles.contentContainer : `${styles.contentContainer} ${styles.introContainer}`}>
        <Row style={hasSaleStarted ? { marginTop: '6em' } : {}}>
          <Col className="col-12 col-md-9">
            <p className={styles.introText}>
              <span className={styles.highlightedText}>Habitats</span> represents a first-of-its-kind research project powered by <a href={"https://www.shoparc.com/"} target="_blank">SHoP Architects</a>, a world-class architecture firm, that will use the Metaverse as a proving ground to innovate and design smarter in the real world. 
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={`${styles.contentContainer} ${styles.renderedPavilionContainer}`} fluid>
        <Row className={styles.content}>
          <Col className={`col-sm-6 col-md-4 ${styles.hideComponentMedium}`}>
            <ReactPlayer 
              url={SpinningHab2}
              playing={true} 
              loop={true} 
              controls={false}
              muted={true}
              playsinline={true}
            />
          </Col>
          <Col className={`col-sm-6 col-md-4 ${styles.hideComponent}`}>
            <ReactPlayer 
              url={SpinningHab1}
              playing={true} 
              loop={true} 
              controls={false}
              muted={true}
              playsinline={true}
            />
          </Col>
          <Col className={`col-sm-6 col-md-4 ${styles.hideComponentLarge}`}>
            <ReactPlayer 
              url={SpinningHab3}
              playing={true} 
              loop={true} 
              controls={false}
              muted={true}
              playsinline={true}
            />
          </Col>
        </Row>
      </Container>
      <Container className={styles.contentContainer}>
        <Row>
          <Col className="col-12 col-md-3">
            <div className={styles.dashSectionBreak} />
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-10">
            <p className={styles.descriptionText}>
              <span className={styles.highlightedText}>Habitats</span> believes the Metaverse is viable and we are committed to assist in it’s growth. We intend to build off our design for communities in the physical world and extend that commitment to better online spaces. The Metaverse provides a platform to enrich and engage communities that we have not been able to in the past. SHoP is here to learn and shape its future.
            </p>
          </Col>
        </Row>
        <Row className={styles.moduleRow}>
          <Col className={`${styles.videoContainer} col-12 col-md-10`}>
            <div className={styles.promoVideoContainer}>
              <iframe
                className={styles.promoVideo}
                src="https://player.vimeo.com/video/698392241?h=5747dd484c"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
        <Row className={styles.moduleRow}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-8`}>
            <Image className={styles.moduleImage} src={conceptSmall1} />
          </Col>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-5 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              How a Habitat is experienced is ultimately up to the user - which is why each Habitat grants access to a downloadable model file that can be integrated into a range of leading Metaverse platforms. 
            </p>
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-6`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={conceptSmall2} />
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow}  d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-5`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={conceptSmall4} />
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-3`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={conceptSmall5} />
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column col-12 col-md-4 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              Our work is the future and with Habitats we want everyone to be a part of it. Join in on the discussion, as well as planned events, to help guide what and how we build in the Metaverse and beyond.
            </p>
          </Col>
        </Row>
        <Row className={`${styles.moduleRow} ${styles.reverseRowOnMobile}`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-5 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              Funds raised through the Genesis collection will go back into further research of new design methods and tooling for both architecting within the Metaverse as well as bridging the gap to the world outside.
            </p>
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-6`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={conceptSmall3} />
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-3">
            <div className={styles.dashSectionBreak} />
          </Col>
        </Row>
        <Row>
          <Col>
           <p className={styles.descriptionText}>
              <span className={styles.highlightedText}>Genesis</span> Habitats will launch with a generative collection of 2,500 NFTs living
              on the Ethereum Blockchain and hosted on IPFS. Each NFT will represent one Habitat, a fully-formed 3D structure that can be integrated with multiple Metaverses. 
            </p>
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-7`}>
            <Row className={styles.habPreviewContainer}>
              <Col className={`${styles.habPreview} col-6 col-md-4`}>
                <Image className={`${styles.moduleImage} ${styles.hideImageOnMobile}`} src={habStill10} />
              </Col>
              <Col className={`${styles.moduleColContainer} col-12 col-md-7`}>
                <Image className={`${styles.moduleImage}`} src={habStill8} />
              </Col>
            </Row>
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column align-items-start col-12 col-md-5 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
            Habitat generation was informed by machine learning algorithms developed by SHoP, which learn from a manual design process. Each new generation will improve from the last, with Genesis being the earliest version. 
            </p>
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow}  d-flex justify-content-end`}>
          <Col className={`${styles.moduleColContainerEnd} col-12 col-md-3`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond}`} src={habStill5} />
          </Col>
          <Col className={`${styles.moduleColContainer} col-12 col-md-5`}>
            <Image className={`${styles.moduleImage} ${styles.moduleImageSecond} ${styles.hideComponentSmall}`} src={habStill1} />
          </Col>
        </Row>
        <Row className={` ${styles.moduleRow} d-flex justify-content-start`}>
          <Col className={`${styles.moduleColContainer} col-12 col-md-7`}>
            <Row className={styles.habPreviewContainer}>
               <Col className={`${styles.moduleColContainer} col-12 col-md-8`}>
                <Image className={`${styles.moduleImage}`} src={habStill9} />
              </Col>
              <Col className={`${styles.habPreview} col-6 col-md-4`}>
                <Image className={`${styles.moduleImage} ${styles.hideImageOnMobile}`} src={habStill12} />
              </Col>
            </Row>
          </Col>
          <Col className={`${styles.moduleColContainer} d-flex flex-column align-items-start col-12 col-md-5 col-lg-4`}>
            <div className={styles.dash} />
            <p className={styles.descriptionTextSmall}>
              Habitats is exploring different avenues of research and their associated value propositions. Some initial areas of interest are 3D NFT collections, virtual real estate development, virtual design services and consulting. 
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-3">
            <div className={styles.dashSectionBreak} />
          </Col>
        </Row>
        <Row className='d-flex flex-column justify-content-center align-items-center'>
          <Col className='d-flex flex-column justify-content-start align-items-start'>
            <h2 className={styles.integrationsHeader}>Habitat <span className={styles.highlightedText}>Integrations</span></h2>
          </Col>
          <Col className='d-flex justify-content-start'>
            <Image className={styles.integrationsImage} src={PartnerLogos} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
